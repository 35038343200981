import React from 'react';

import { Page, Section } from 'src/components';

export default function PageNotFound() {
  return (
    <Page title="Enrollment Email" prefooter={<></>}>
      <Section
        narrow
        gradient
        color="sage"
        title="All set!"
        subtitle={
          <div className="o85">
            Congrats on having your insurance in order. We'll spare you our enrollment emails. Sign
            back in to manage all communication settings.
          </div>
        }
      ></Section>
      <Section></Section>
    </Page>
  );
}
